


@media (max-width: 1200px) {
    .home-cont {
        width: 100%;
        padding: 10px;
    }
}

@media (max-width: 999px) {
    .about-box {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 20px;
    }
    .home-icon-cont  {
        flex: 0 0 100%;
        max-width: 100%;
        flex-direction: row !important;
        gap: 10px !important;
        align-items: flex-end !important;
    }
}

@media (max-width: 870px) {
    .about-info-icon {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .about-info-text {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (max-width: 820px) {
    .app-box {
        width: 98%;
    }
}

@media (max-width: 740px) {
    .aha-text-box {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .aha-line-box {
        flex: 0 0 25%;
        max-width: 25%;
    }
}

@media (max-width: 675px) {
    .app-features-container .grid-column-3 {
        grid-template-columns: repeat(2, 1fr);
    }
    .app-box-title {
        font-size: 14px;
    }
    .app-features-container {
        padding: 0 0 10px 7px;
    }
    .ft-nav-btn-cont {
        padding: 0 0 0 7px;
    }
    .features-nav-btn p {
        font-size: 15px;
    }
    .features-nav-btn {
        height: 36px;
        padding: 0 11px;
    }
    .features-nav-btn.active {
        height: 33px;
    }
}

@media (max-width: 470px) {
    .app-features-container .grid-column-3 {
        grid-template-columns: repeat(1, 1fr);
    }
    .dm-address-box {
       padding: 0 7px;
    }
    .domain-box-inner p {
        font-size: 12px;
    }
    .dm-search-box input {
       font-size: 14px;
    }
    .domain-box-inner h3 {
       font-size: 20px;
    }
    .domain-box-inner h4 {
        font-size: 17px;
    }
    .warn-container {
        width: 98%;
    }
    .icon-item img.vista {
        width: 45px;
    }
    .icon-item img {
        margin-bottom: 3px;
    }
    .icon-item .rcy {
        width: 70px;
    }
    .icon-item .info {
        width: 55px;
    }
    .icon-title-box {
        width: 100px;
    }
    .icon-title-box {
        width: 100px;
        height: 30px;
    }
    .icon-title-box p {
        font-size: 12px;
    }
}

@media (max-width: 700px) {
    .home-header {
        padding: 2px 10px;
    }
    .home-header-rcy-box p {
        font-size: 21px;
    }
    .home-header-rcy-box img {
        width: 33px;
        height: 33px;
    }
    .home-header-btn {
        width: 150px;
        height: 30px;
    }
    .hh_btn_icon_box {
        width: 20px;
        height: 20px;
    }
    .home-header-btn p {
        font-weight: 600;
        font-size: 17px;
    }
    .home-header-btn.header-connect {
        width: 73px;
    }
    .home-header-btn.header-connect p {
        font-size: 12px;
        font-weight: 700;
    }
    .home-header .gap-5 {
        gap: 0;
    }
    .home-header .gap-10 {
        gap: 3px;
    }
    .home-content-box {
        padding: 70px 10px;
    }
    .about-info-cont {
        padding: 20px 15px;
    }
    .ca-box h2 {
        font-size: 10px;
    }
    .about-info-box {
        padding: 20px 10px;
    }
    .about-info-text p {
        font-size: 14px;
    }
    .about-info-text h3 {
        font-size: 17px;
    }
    .about-info-cont .gap-10 {
        gap: 0;
    }
    .ca-box {
        width: 100%;
        padding: 9px 4px;
    }
    .connect-btn {
        width: 125px;
    }
}




