@font-face {
    font-family: 'roboto light';
    src: url(./../fonts/roboto/Roboto-Light.ttf);
}
@font-face {
    font-family: 'roboto medium';
    src: url(./../fonts/roboto/Roboto-Medium.ttf);
}
@font-face {
    font-family: 'spacemono regular';
    src: url(./../fonts/spacemono/SpaceMono-Regular.ttf);
}
@font-face {
    font-family: 'poppins bold';
    src: url(./../fonts/poppins/Poppins-Bold.ttf);
}
@font-face {
    font-family: 'poppins medium';
    src: url(./../fonts/poppins/Poppins-Medium.ttf);
}
@font-face {
    font-family: 'poppins regular';
    src: url(./../fonts/poppins/Poppins-Regular.ttf);
}
@font-face {
    font-family: 'poppins light';
    src: url(./../fonts/poppins/Poppins-Light.ttf);
}
@font-face {
    font-family: 'acosta';
    src: url(./../fonts/acosta/Acosta.otf);
}
@font-face {
    font-family: 'montserrat';
    src: url(./../fonts/montserrat-v25-latin-regular.ttf);
}
@font-face {
    font-family: 'cherry';
    src: url(./../fonts/CHERI___.TTF);
}
@font-face {
    font-family: 'inter';
    src: url(./../fonts/Inter-VariableFont_slnt\,wght.ttf);
}
@font-face {
    font-family: 'jersey-10';
    src: url(./../fonts/Jerse-10-Regular.ttf);
}
@font-face {
    font-family: 'kant';
    src: url(./../fonts/kantmury/KantumruyPro-VariableFont_wght.ttf);
}
@font-face {
    font-family: 'kant italic';
    src: url(./../fonts/kantmury/KantumruyPro-Italic-VariableFont_wght.ttf);
}

/* -------------------
custom_-_variables
------------------ */
:root {
    /* -------- defaults -------- */
    --d-white: #FFFFFF;
    --d-body: #FCFDFE;
    --d-black: #161718;

    /* -------- neutral -------- */
    --n50: #FFFFFF;
    --n75: #FFFFFF;
    --n100: #FFFFFF;
    --n200: #FFFFFF;
    --n300: #F9F9F9;
    --n400: #B3B3B3;
    --n500: #9C9C9C;

    /* -------- primary -------- */
    --p50: #FFECE6;
    --p75: #FFB396;
    --p100: #FF936B;
    --p200: #FF652B;
    --p300: #FF4500;
    --p400: #B33000;
    --p500: #9C2A00;

    /* -------- secondary -------- */
    /* --s50: #E6F8FF;
    --s75: #96E3FF;
    --s100: #6BD7FF;
    --s200: #2BC6FF;
    --s300: #00BAFF;
    --s400: #0082B3;
    --s500: #00719C; */
    --s50: #E8E8EA;
    --s75: #A1A1AB;
    --s100: #7A7A88;
    --s200: #414154;
    --s300: #1A1A31;
    --s400: #121222;
    --s500: #10101E;

    /* work_-_cc */
    --light-blue: #1D2235;
    --lighter-blue: #252B43;
    --bright-blue: #252B43;
    --dark-blue: #16192A;
    --flash-blue: #5C26F6;

    /* layer_-_cc */
    --layer-main-dark: #000000;
    --layer-light-dark: #1C1C1C;
    --layer-white: #F2F2F2;
    --layer-color-dark: #878787;
    --layer-lighter-dark: rgba(242, 242, 242, 0.12);

    /* logo_-_cc */
    --logo-light-red: #ED051F;
    --logo-dark-red: #75000E;
    --logo-light-blue: #120B34;
    --logo-dark-blue: #0D0D1F;

    /* rich_-_cc */
    --rich-green-main: #428432;
    --rich-green-grad-1: #A2F39B;
    --rich-green-grad-2: #0B9F05;
    --rich-green-grad-3: #3C7F2C;
    --rich-yellow-main: #EECF02;
    --rich-red: #E31B1E;

    /* peki-inu_-_cc */
    --peki-b1: #02041A;
    --peki-b2: #403E56;
    --peki-y1: #FDE66F;
    --peki-y2: #FCBF30;
    --peki-y2: #FCBF30;
    --peki-y3: #FFCD6B;
    --peki-y4: #FED001;
    --peki-y5: #D98801;
    --peki-y6: #F7C244;
    --peki-p1: #FA0282;
    --peki-g1: #9D9D4C;
    --peki-g2: #BCBC6B;

    --bp-y1: #F7D58E;
    --bp-y2: #FA8801;
    --bp-y3: #CC4801;
    --bp-y3: #CC4801;

    --bp-w1: #F8EECB;
    --bp-w2: #C5977B;

    --bp-br1: #4D1B14;
    --bp-br2: #81040F;
    --bp-br3: #FD352E;
    --bp-br4: #FC9687;

    /* pop_-_cc */
    --pop-g1: #E36E11;
    --pop-g2: #FDA529;
    --pop-g3: #FEDB4B;
    --pop-b1: #007ABF;
    --pop-b1: #022365;
    --pop-gr1: #17AE2D;

    /* dog_-_cc */
    --dog-y1: #FEF830;
    --dog-y2: #FF9201;
    --dog-y3: #FFDF01;
    --dog-y4: #BC4028;
    --dog-y5: #E27017;

    --dog-p1: #F10271;
    --dog-p2: #FB1F41;
    --dog-p3: #D70190;
    --dog-p4: #F567A6;

    --dog-b1: #0B417D;
    --dog-b2: #03C6F2;
    --dog-b3: #0163D0;
    --dog-b4: #04003D;

    /* trump-cat_-_cc */
    --tc-r1: #E45959;
    --tc-r2: #FB6648;
    --tc-r3: #E40E07;

    --tc-b1: #71A8F3;
    --tc-b2: #63B5DB;
    --tc-b3: #467A9A;
    --tc-b4: #B0E3F6;
    --tc-b4: #1ED5E7;

    --tc-w1: #FDFDFD;
    --tc-w2: #A09C99;

    --tc-br1: #F8B566;
    --tc-br2: #FAAB70;
    --tc-br3: #FDD283;

    /* vista_-_cc */
    --vista-dark: rgba(47, 47, 47, 1);

    /* flexible */

    /*  */
    --error-bg: #ffefef;
    --error-act: #e7195a;
    --sus-act: #008000;
}


/* -------------------
global_-_styles
------------------- */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

.home {
    overflow-y: hidden;
}
.picker-result-box {
    overflow-y: hidden;
}

*:not(.the-feedbacks) {
    overflow-x: hidden;
}

.news-column-2 .height-50 {
    height: 193px;
}

*:not(i) {
    font-family: 'roboto light';
    /* font-weight: 400; */
}

::before,
::after {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

body {
    scroll-behavior: smooth;
    overflow-x: hidden;
    background: var(--tc-b3);
    /* background: var(--layer-main-dark); */
    /* background: var(--logo-dark-blue); */
}

.flex-100 {
    flex: 0 0 100%;
    max-width: 100%;
}

.btn-og {
    display: inline-block;
    background: var(--rich-green-main);
    /* background: var(--logo-dark-red); */
    padding: 0 27px;
    height: 45px;
    border-radius: 30px;
    cursor: pointer;
    transition: .5s;
}
.btn-og p {
    font-size: 18px;
    font-family: 'poppins medium';
    /* color: var(--layer-main-dark); */
    color: var(--layer-white);
}

.btn-og.dark {
    /* background: var(--layer-light-dark); */
    background: var(--logo-light-blue);
}
.btn-og.dark p {
    color: var(--d-white);
}

.btn-1 {
    display: inline-block;
    padding: 12px 25px;
    background: linear-gradient(to bottom, var(--tc-r1), var(--tc-r3));
    border: 2px solid var(--layer-main-dark);
    border-radius: 30px;
    font-size: 18px;
    font-weight: 100;
    color: var(--layer-white);
    font-family: "cherry";
    cursor: pointer;
    transition: .5s;
}

.btn-sp {
    display: inline-block;
    padding: 12px 20px;
    background: var(--peki-p1);
    border-radius: 30px;
    font-size: 17px;
    font-weight: 600;
    color: var(--n75);
    cursor: pointer;
    transition: .5s;
}

.btn-2 {
    display: inline-block;
    padding: 15px 35px;
    background: transparent;
    border-radius: 35px;
    font-family: 'open sans';
    font-size: 18;
    font-weight: 600;
    color: var(--t300);
    transition: .5s;
}
.btn-2:hover {
    background: var(--n300);
}

.main-frame {
    max-width: 1300px;
    margin: 0 auto;
}

@media (max-width: 1300px) {
    .main-frame {
        max-width: 100%;
        padding: 0 15px;
        margin: 0 auto;
    }
}

.wider-frame-percentage {
    max-width: 96%;
    margin: 0 auto;
}

.mid-frame {
    width: 1000px;
    margin: 0 auto;
}
@media (max-width: 1000px) {
    .mid-frame {
        max-width: 100%;
        padding: 0 15px;
        margin: 0 auto;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.position-relative {
    position: relative;
}

.center-width {
    margin: 0 auto;
}

.transition-05 {
    transition: .5s;
}

.display-inline-block {
    display: inline-block;
}
.display-inline {
    display: inline;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.red_b {
    background: red;
}
.purple_b {
    background: purple;
}
.wheat_b {
    background: wheat;
}

.backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, .25);
    z-index: 111;
}

.toast-container {
    position: fixed;
    top: -70px;
    z-index: 999;
    width: 100%;
    transition: .2s;
}
.toast-container.active {
    top: 0;
    
}

.toast-box {
    padding: 20px 20px;
    background: rebeccapurple;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}
.toast-box.error {
    background: crimson;
}
.toast-box.nice {
    background: #4BB543;
}

.toast-box p {
    color: var(--n75);
}

.width-100-percet {
    width: 100%;
}

/* ------------------------------------
animation_-_s
-------------------------------------- */

@keyframes back {
    100% {
        background-position: 2000px 0;
    }
}

@keyframes fade {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes loadText {
    0% {
        width: 0;
    }
    70% {
        width: 100%;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes topBounce {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(25px);
    }
}

@keyframes leftBounce {
    0%,
    100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(25px);
    }
}

@keyframes animateWave {
    0% {
        background-position-x: 1000px;
    }
    100% {
        background-position-x: 0px;
    }
}

@keyframes animateWave_02 {
    0% {
        background-position-x: 0px;
    }
    100% {
        background-position-x: 1000px;
    }
}

@keyframes moving {
    0% {
        left: -20px;
    }
    100% {
        left: 100%;
    }
}

@keyframes bounce {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(15px);
    }
}

@keyframes scaleAnt {
    0%,
    100% {
        scale: .9;
    }
    50% {
        scale: 1;
    }
}

@keyframes shakeScale {
    0% {
        transform: rotateX(0deg);
    }
    10% {
        transform: rotateX(-20deg);
    }
    20% {
        transform: rotateX(20deg);
    }
    30% {
        transform: rotateX(-30deg);
    }
    40% {
        transform: rotateX(30deg);
    }
    50% {
        transform: translateX(0);
    }
    60% {
        scale: .7;
    }
    10% {
        scale: 1;
    }
}


/* home_-_styles */
.home {
    background-image: url(./../media/img/vista_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    overflow-y: auto;
}

.taskbar {
    width: 100%;
    background: rgba(191, 191, 191, 1);
    padding: 2px 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
}

.tsk_bar_btn {
    box-shadow: rgba(255, 255, 255, 1) 1.32px 1.32px inset, rgba(0, 0, 0, 1) -1.32px -1.32px inset, rgba(219, 219, 219, 1) 2.64px 2.64px inset, rgba(128, 128, 128, 1) -2.64px -2.64px inset;
    height: 37px;
    padding: 0 10px;
    background: rgba(191, 191, 191, 1);
}

.tsk_bar_icon_box {
    width: 30px;
    height: 30px;
    overflow-y: hidden;
}
.tsk_bar_icon_box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.tsk_bar_icon_box img.info_one {
    width: 95%;
    height: 95%;
    object-fit: contain;
}

.tsk_bar_btn p {
    font-size: 22px;
    font-weight: 400;
    font-family: 'jersey-10';
    color: var(--vista-dark);
}

.side-links-box.active {
    left: 10px;
}
.side-links-box {
    position: fixed;
    bottom: 45px;
    left: -170px;
    box-shadow: rgba(255, 255, 255, 1) 1.32px 1.32px inset, rgba(0, 0, 0, 1) -1.32px -1.32px inset, rgba(219, 219, 219, 1) 2.64px 2.64px inset, rgba(128, 128, 128, 1) -2.64px -2.64px inset;
    background: rgba(191, 191, 191, 1);
    padding: 10px;
    z-index: 999;
    transition: .5s;
}

.side-links-btn {
    width: 100px;
    height: 35px;
    box-shadow: rgba(255, 255, 255, 1) 1.32px 1.32px inset, rgba(0, 0, 0, 1) -1.32px -1.32px inset, rgba(219, 219, 219, 1) 2.64px 2.64px inset, rgba(128, 128, 128, 1) -2.64px -2.64px inset;
    background: rgba(191, 191, 191, 1);
}
.side-links-btn:not(:last-child) {
    margin-bottom: 7px;
}

.side-links-btn p {
    font-size: 20px;
    font-weight: 500;
    font-family: 'jersey-10';
    color: var(--vista-dark);
}

.side-links-control.active {
    bottom: 190px;
}
.side-links-control {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    box-shadow: rgba(255, 255, 255, 1) 1.32px 1.32px inset, rgba(0, 0, 0, 1) -1.32px -1.32px inset, rgba(219, 219, 219, 1) 2.64px 2.64px inset, rgba(128, 128, 128, 1) -2.64px -2.64px inset;
    background: rgba(191, 191, 191, 1);
    position: fixed;
    bottom: 44px;
    left: 10px;
    z-index: 999;
    transition: .5s;
}
.side-links-control img {
    width: 15px;
}

.time-box p {
    font-size: 20px;
    font-weight: 500;
    font-family: 'inter';
    color: var(--vista-dark);
}


.home-header {
    width: 100%;
    background: rgba(191, 191, 191, 1);
    padding: 2px 60px;
    position: fixed;
    z-index: 999;
}

.home-header-rcy-box img {
    width: 40px;
    height: 40px;
}
.home-header-rcy-box p {
    font-size: 22px;
    font-weight: 500;
    font-family: 'jersey-10';
}

.home-header-btn {
    box-shadow: rgba(255, 255, 255, 1) 1.32px 1.32px inset, rgba(0, 0, 0, 1) -1.32px -1.32px inset, rgba(219, 219, 219, 1) 2.64px 2.64px inset, rgba(128, 128, 128, 1) -2.64px -2.64px inset;
    width: 160px;
    height: 40px;
    background: rgba(191, 191, 191, 1);
}
.hh_btn_icon_box {
    width: 30px;
    height: 30px;
    overflow-y: hidden;
}
.hh_btn_icon_box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.home-header-btn p {
    font-weight: 500;
    font-size: 20px;
    font-family: 'jersey-10';
    color: var(--vista-dark);
}

.home-content-box {
    padding: 70px 60px;
}

.close-box {
    position: absolute;
    top: 0;
    right: 15px;
    box-shadow: rgba(255, 255, 255, 1) 1.32px 1.32px inset, rgba(0, 0, 0, 1) -1.32px -1.32px inset, rgba(219, 219, 219, 1) 2.64px 2.64px inset, rgba(128, 128, 128, 1) -2.64px -2.64px inset;
    width: 22px;
    height: 100%;
    background: rgba(192, 192, 192, 1);
    /* z-index: 999; */
}
.close-box img {
    width: 8px;
}

.about-box {
    background: rgba(192, 192, 192, 1);
    box-shadow: rgba(255, 255, 255, 1) 1.32px 1.32px inset, rgba(0, 0, 0, 1) -1.32px -1.32px inset, rgba(219, 219, 219, 1) 2.64px 2.64px inset, rgba(128, 128, 128, 1) -2.64px -2.64px inset;
    width: 760px;
}

.about-header-box {
    background: rgba(255, 255, 255, 1);
    width: 100%;
    box-shadow: rgba(255, 255, 255, 1) 1.32px 1.32px inset, rgba(0, 0, 0, 1) -1.32px -1.32px inset, rgba(219, 219, 219, 1) 2.64px 2.64px inset, rgba(128, 128, 128, 1) -2.64px -2.64px inset;
    height: 50px;
}

.aha-dg {
    height: 23px;
    width: 100%;
}

.aha-line-box {
    flex: 0 0 37.5%;
    max-width: 37.5%;
    /* background: red; */
}

.aha-line-box {
    padding-top: 3px;
    padding-bottom: 3px;
}
.aha-line-box.left {
    padding-left: 3px;
}
.aha-line-box.right {
    padding-right: 3px;
}
.aha-line-box div{
    width: 100%;
    height: 1.6px;
    background: linear-gradient(rgba(1, 2, 129, 1), rgba(15, 128, 205, 1));
}

.aha-text-box {
    flex: 0 0 25%;
    max-width: 25%;
    background: linear-gradient(rgba(7, 56, 162, 1), rgba(9, 77, 174, 1));
}

.aha-text-box p {
    font-family: 'jersey-10';
    font-weight: 500;
    font-size: 22px;
    color: rgba(255, 255, 255, 1);
}

.about-info-cont {
    padding: 30px 30px 25px 30px;
}

.about-info-box {
    box-shadow: rgba(255, 255, 255, 1) 1.32px 1.32px inset, rgba(0, 0, 0, 1) -1.32px -1.32px inset, rgba(219, 219, 219, 1) 2.64px 2.64px inset, rgba(128, 128, 128, 1) -2.64px -2.64px inset;
    padding: 20px 40px 25px 15px;
}

.about-info-icon img {
    width: 120px;
}

.about-info-text {
    max-width: 500px;
    padding-top: 20px;
}

.about-info-text p {
    color: var(--vista-dark);
    font-family: 'inter';
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
}

.about-info-text h3 {
    color: var(--vista-dark);
    font-family: 'jersey-10';
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 5px;
}

.ca-box {
    width: 90%;
    border: 1.5px solid rgba(128, 128, 128, 1);
    padding: 9px 13px;
    margin-bottom: 17px
}

.ca-box h2 {
    font-size: 14px;
    font-family: 'kant';
    font-weight: 400;
    color: var(--vista-dark);
}

.about-info-line {
    width: 100%;
    height: 1.5px;
    background: rgba(128, 128, 128, 1);
    margin-bottom: 15px;
}

.connect-btn {
    width: 180px;
    height: 35px;
    box-shadow: rgba(255, 255, 255, 1) 1.32px 1.32px inset, rgba(0, 0, 0, 1) -1.32px -1.32px inset, rgba(219, 219, 219, 1) 2.64px 2.64px inset, rgba(128, 128, 128, 1) -2.64px -2.64px inset;
}

.connect-btn img {
    width: 20px;
    height: 20px;
}

.connect-btn p {
    font-weight: 700;
    font-size: 20px;
    font-family: 'jersey-10';
    color: var(--vista-dark);
    margin-bottom: 0;
}
    
.icon-item img {
    margin-bottom: 9px;
}
    
.icon-item .rcy {
    width: 100px;
}

.icon-item .vista {
    width: 63px;
}

.icon-item .info {
    width: 70px;
}

.icon-title-box {
    width: 145px;
    height: 42px;
    background: rgba(255, 255, 255, 1);
}

.icon-title-box p {
    font-size: 16px;
    font-family: 'inter';
    font-weight: 600;
}



/* app_-_dialogue_-_box_-_styles */
.app-box {
    background: rgba(192, 192, 192, 1);
    box-shadow: rgba(255, 255, 255, 1) 1.32px 1.32px inset, rgba(0, 0, 0, 1) -1.32px -1.32px inset, rgba(219, 219, 219, 1) 2.64px 2.64px inset, rgba(128, 128, 128, 1) -2.64px -2.64px inset;
    width: 800px;
    height: 650px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    scale: 0.5;
    opacity: 0;
    pointer-events: none;
    z-index: 99;
    overflow-y: hidden;
    transition: .5s;
}

.app-box.active {
    scale: 1;
    opacity: 1;
    pointer-events: all;
    
}

.app-box-title {
    margin-top: 7px;
    margin-bottom: 15px;
    font-size: 22px;
    font-weight: 700;
    font-family: 'jersey-10';
    color: var(--vista-dark);
    text-align: center;
}

.ft-nav-btn-cont {
    padding: 0 0 0 20px;
    overflow-y: hidden;
}

.features-nav-btn {
    box-shadow: rgba(255, 255, 255, 1) 1.32px 1.32px inset, rgba(0, 0, 0, 1) -1.32px -1.32px inset, rgba(219, 219, 219, 1) 2.64px 2.64px inset, rgba(128, 128, 128, 1) -2.64px -2.64px inset;
    height: 40px;
    padding: 0 13px;
    margin-bottom: -3px;
}
.features-nav-btn.active {
    margin-bottom: 0;
    height: 37px;
}

.features-nav-btn p {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 19px;
    font-family: 'jersey-10';
    color: var(--vista-dark);
}
.features-nav-btn.active p {
    color: var(--flash-blue);
}

.none_empt {
    font-weight: 600;
    font-size: 22px;
    font-family: 'jersey-10';
    color: var(--vista-dark);
    text-align: center;
}

.app-features-container {
    width: 100%;
    padding: 0 0 10px 20px;
    height: 77%;
}
.app-features-container::-webkit-scrollbar {
    width: 25px;               /* Width of the scrollbar */
}
.app-features-container::-webkit-scrollbar-track {
    background: rgba(190, 194, 197, 1);        /* Color of the scrollbar track */
    border: 1.5px solid rgba(0, 0, 0, 1);
}
.app-features-container::-webkit-scrollbar-thumb {
  background: rgba(190, 194, 197, 1);           /* Color of the scrollbar handle */
  border: 1.5px solid rgba(0, 0, 0, 1);
  box-shadow: rgba(255, 255, 255, 1) 1.32px 1.32px inset, rgba(0, 0, 0, 1) -1.32px -1.32px inset, rgba(219, 219, 219, 1) 2.64px 2.64px inset, rgba(128, 128, 128, 1) -2.64px -2.64px inset;
}

.app-features-box {
    width: 100%;
    box-shadow: rgba(255, 255, 255, 1) 1.32px 1.32px inset, rgba(0, 0, 0, 1) -1.32px -1.32px inset, rgba(219, 219, 219, 1) 2.64px 2.64px inset, rgba(128, 128, 128, 1) -2.64px -2.64px inset;
    padding: 10px;
}

.fi-item-box {
    box-shadow: rgba(255, 255, 255, 1) 1.32px 1.32px inset, rgba(0, 0, 0, 1) -1.32px -1.32px inset, rgba(219, 219, 219, 1) 2.64px 2.64px inset, rgba(128, 128, 128, 1) -2.64px -2.64px inset;
    padding: 6px;
}

.fi-item-img-box {
    width: 100%;
    height: 200px;
    margin-bottom: 5px;
    overflow-y: hidden;
}

.fi-item-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.fi-item-box h3 {
    font-family: 'jersey-10';
    font-size: 20px;
    font-weight: 600;
    color: var(--vista-dark);
}

.fi-item-box p {
    font-family: 'inter';
    font-weight: 400;
    font-size: 15px;
}

.ca-show {
    background: rgba(255, 255, 255, 1);
    box-shadow: rgba(0, 0, 0, 1) 1.32px 1.32px inset, rgba(255, 255, 255, 1) -1.32px -1.32px inset, rgba(128, 128, 128, 1) 2.64px 2.64px inset, rgba(223, 223, 223, 1) -2.64px -2.64px inset;
    width: 140px;
    height: 30px;
}

.ca-show h4 {
    font-family: 'kant';
    font-weight: 400;
    font-size: 15px;
    color: var(--vista-dark);
}

.burn-btn {
    width: 100px;
    height: 30px;
    box-shadow: rgba(255, 255, 255, 1) 1.32px 1.32px inset, rgba(0, 0, 0, 1) -1.32px -1.32px inset, rgba(219, 219, 219, 1) 2.64px 2.64px inset, rgba(128, 128, 128, 1) -2.64px -2.64px inset;
}

.burn-btn h5 span {
    /* margin-right: 1px; */
    font-size: 16px;
}
.burn-btn h5 {
    font-weight: 600;
    font-size: 16px;
    font-family: 'jersey-10';
    color: var(--vista-dark);
}

.warn-icon-box {
    width: 25px;
    height: 24px;
    overflow-y: hidden;
}

.warn-icon-box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.domain-box {
    max-width: 580px;
    margin: 0 auto;
    padding: 20px 0;
}

.domain-box-inner h3 {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    font-family: 'jersey-10';
    color: var(--vista-dark);
    margin-bottom: 12px;
}

.dm-search-box {
    width: 420px;
    height: 40px;
    background: rgba(255, 255, 255, 1);
    box-shadow: rgba(0, 0, 0, 1) 1.32px 1.32px inset, rgba(255, 255, 255, 1) -1.32px -1.32px inset, rgba(128, 128, 128, 1) 2.64px 2.64px inset, rgba(223, 223, 223, 1) -2.64px -2.64px inset;
    padding: 0 10px;

}

.dm-search-box img {
    width: 25px;
}

.dm-search-box input {
    font-family: 'kant';
    font-weight: 500;
    font-size: 16px;
    color: var(--vista-dark);
    width: 100%;
    outline: none;
    border: none;
}
.dm-search-box input::placeholder {
    font-weight: 400;
    color: rgba(113, 110, 110, 1);

}

.domain-box-inner h4 {
    font-size: 20px;
    font-weight: 600;
    font-family: 'jersey-10';
    color: var(--vista-dark);
    margin-bottom: 7px;
}

.domain-box-inner p {
    font-size: 15px;
    font-weight: 400;
    font-family: 'kant';
    color: var(--vista-dark);
}

.dm-address-box {
    width: 100%;
    height: 40px;
    padding: 0 20px;
    border: 1.5px solid rgba(255, 255, 255, 1);
}

.lp-item {
    box-shadow: rgba(255, 255, 255, 1) 1.32px 1.32px inset, rgba(0, 0, 0, 1) -1.32px -1.32px inset, rgba(219, 219, 219, 1) 2.64px 2.64px inset, rgba(128, 128, 128, 1) -2.64px -2.64px inset;
    padding: 20px 10px;
}

.lp-icon-box {
    margin-bottom: 15px;
}

.lp-icon-box-item {
    width: 53px;
    height: 53px;
}

.lp-icon-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.lp-icon-box-item.shift {
    margin-left: -10px;
}

.lp-item h3 {
    font-size: 18px;
    font-weight: 600;
    font-family: 'jersey-10';
    color: var(--vista-dark);
    text-align: center;
    margin-bottom: 4px;
}

.lp-item-ca-show {
    width: 180px;
    height: 29px;
    background: rgba(255, 255, 255, 1);
    box-shadow: rgba(0, 0, 0, 1) 1.32px 1.32px inset, rgba(255, 255, 255, 1) -1.32px -1.32px inset, rgba(128, 128, 128, 1) 2.64px 2.64px inset, rgba(223, 223, 223, 1) -2.64px -2.64px inset;
    margin-bottom: 18px;
}

.lp-item-ca-show p {
    font-size: 15px;
    font-weight: 400;
    font-family: 'kant';
    color: var(--vista-dark);
}

.warn-container {
    background: rgba(192, 192, 192, 1);
    box-shadow: rgba(255, 255, 255, 1) 1.32px 1.32px inset, rgba(0, 0, 0, 1) -1.32px -1.32px inset, rgba(219, 219, 219, 1) 2.64px 2.64px inset, rgba(128, 128, 128, 1) -2.64px -2.64px inset;
    width: 384px;
    /* height: 650px; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    scale: 0.5;
    opacity: 0;
    pointer-events: none;
    z-index: 999;
    overflow-y: hidden;
    transition: .5s;
}

.warn-container.active {
    scale: 1;
    opacity: 1;
    pointer-events: all;
}

.warn-content {
    padding: 27px 20px;
}

.warn-content img {
    width: 70px;
    margin-bottom: 18px;
}

.warn-content p {
    font-size: 17px;
    font-weight: 400;
    font-family: 'inter';
    color: var(--vista-dark);
    margin-bottom: 18px;
}




















